import { useContext, useEffect, useState } from "react"
import { AppContext } from "../contexts"
import { SET_LOADING, SET_PAYMENT_INPUT, SET_PROGRESS, SWITCH_LANGUAGE } from "../helpers/constants"
import { ErrorResponse, PaymentInput, Progress } from "../helpers/enums"

const useApp = () => {
  const { state, dispatch } = useContext(AppContext)
  const [error, setError] = useState<ErrorResponse>(ErrorResponse.None)
  const [isError, setIsError] = useState<boolean>(false)

  useEffect(() => {
    setIsError(
      [
        ErrorResponse.LinkExpiredDate,
        ErrorResponse.InternalServer,
        ErrorResponse.Unknown,
        ErrorResponse.UnknownStatus,
        ErrorResponse.NoAccess
      ].includes(error)
    )
  }, [error])

  const startLoading = () => {
    dispatch({ type: SET_LOADING, payload: true })
  }

  const stopLoading = () => {
    dispatch({ type: SET_LOADING, payload: false })
  }

  const progressTo = (type: Progress) => {
    dispatch({ type: SET_PROGRESS, payload: type })
  }

  const setPaymentInput = (input: PaymentInput) => {
    dispatch({ type: SET_PAYMENT_INPUT, payload: input })
  }

  const setLang = (lang: string) => {
    dispatch({ type: SWITCH_LANGUAGE, payload: lang })
  }

  return {
    state,
    isError,
    error,
    startLoading,
    stopLoading,
    progressTo,
    setPaymentInput,
    setLang,
    setError,
  }
}

export default useApp
