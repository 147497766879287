import { message } from "../translate.helper"

const th = {
  [message.purchase_details]: "รายละเอียดการซื้อ",
  [message.see_refund_policy]: "ดูนโยบายการคืนเงิน",
  [message.here]: "ที่นี่",
  [message.pay_before]: "ชำระเงินก่อน",
  [message.payment_successful]: "ชำระเงินสำเร็จ",
  [message.payment_pending]: "กำลังดำเนินการชำระเงิน",
  [message.payment_pending_desc]: "กำลังติดต่อร้านค้าเพื่อยืนยันการชำระเงิน กรุณารอการติดต่อกลับในภายหลัง",
  [message.payment_date]: "วันที่ชำระเงิน",
  [message.payment_method]: "ช่องทางชำระเงิน",
  [message.back_to_merchant]: "กลับไปที่หน้าของร้านค้า",
  [message.save_to_files]: "ดาวน์โหลดไฟล์",
  [message.share_to_email]: "ส่งอีเมล์",
  [message.mail_receipt]: "ส่งใบเสร็จไปยังอีเมล์",
  [message.enter_email_send_receipt]: "กรอกอีเมลเพื่อส่งใบเสร็จ",
  [message.email]: "อีเมล์",
  [message.cancel]: "ยกเลิก",
  [message.send]: "ส่ง",
  [message.receipt_sent]: "ส่งใบเสร็จผ่านทางอีเมล์เรียบร้อยแล้ว",
  [message.oops_link_is_invalid]: "ลิงก์ไม่ถูกต้อง",
  [message.link_has_been_used_or_doesnt_exist]: "ลิงก์ที่คุณกำลังค้นหาถูกใช้แล้วหรือไม่มีอยู่ในระบบ",
  [message.unable_to_process_the_payment]: "ไม่สามารถดำเนินการชำระเงินได้",
  [message.unable_to_send_email]: "ไม่สามารถส่งอีเมล์ได้ในขณะนี้",
  [message.done]: "เสร็จสิ้น",
  [message.payment_has_been_finished]: "กระบวนการชำระเงินของคุณเสร็จสิ้น คุณสามารถปิดเพย์ลิงค์นี้ได้ทันที",
  [message.please_follow_instructions_payment]: "โปรดปฏิบัติตามคำแนะนำด้านล่างเพื่อชำระเงิน",
  [message.qr_code_desc_desktop_no_1]: "เปิดแอปธนาคารที่คุณต้องการ",
  [message.qr_code_desc_desktop_no_2]: "สแกนคิวอาร์โค้ด",
  [message.qr_code_desc_desktop_no_3]: "ตรวจสอบยอดเงินและอนุมัติการชำระเงิน",
  [message.qr_code_desc_desktop_no_4]: "หน้าจะรีเฟรชเมื่อได้รับการชำระเงินสำเร็จ",
  [message.qr_code_desc_mobile_no_1]: "สกรีนช็อต / บันทึกรหัส QR ไปยังแกลเลอรี่รูปภาพของคุณ",
  [message.qr_code_desc_mobile_no_2]: "เปิดแอปธนาคารที่ต้องการ ไปที่ \"สแกน QR\"",
  [message.qr_code_desc_mobile_no_3]: "อัปโหลด / นำเข้ารหัส QR จากอัลบั้ม / แกลเลอรี่ภาพ",
  [message.qr_code_desc_mobile_no_4]: "ตรวจสอบยอดเงินและอนุมัติการชำระเงิน",
  [message.qr_code_desc_mobile_no_5]: "กลับไปที่หน้าชำระเงินของคุณบนเบราว์เซอร์ / แอพ",
  [message.qr_code_desc_mobile_no_6]: "หน้าจะรีเฟรชเมื่อได้รับการชำระเงินสำเร็จ",
  [message.qr_code_please_notes]: "โปรดทราบ:",
  [message.qr_code_please_notes_no_1]: "คุณจะต้องชำระเงินให้กับ Omise Payment Singapore Pte. Ltd ผู้ประมวลผลการชำระเงินที่ได้รับอนุญาตในนามของเรา",
  [message.qr_code_please_notes_no_2]: "QR หนึ่งอันใช้สำหรับคำสั่งซื้อเดียวเท่านั้น โปรดอย่าชำระเงินหลายรายการด้วย QR . เดียวกัน",
  [message.download_qr_code]: "ดาวน์โหลดคิวอาร์โค้ด",
  [message.payment_failed]: "ชำระเงินล้มเหลว",
  
  [message.no_access_title]: "บัญชีร้านค้าไม่พร้อมใช้งาน",
  [message.no_access_description]: "ไม่สามารถทำรายการลิงก์การชำระเงินนี้ได้ในขณะนี้ หากมีคำถามหรือต้องการความช่วยเหลือเพิ่มเติม กรุณาติดต่อร้านค้าโดยตรง",
}

export default th
