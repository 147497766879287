import styled from "styled-components"
import { media } from "../../helpers/styles"
import Card, { CardBody, CardFooter, CardHead } from "../Build/Card"
import Icon, { IconList } from "../Build/Icon"

type Props = {
  message: string;
  description: string;
  retryMessage?:string;
  retry?:()=>void
  icon? : IconList
}

const ErrorMessage = ({icon, message, description, retryMessage, retry }: Props) => {
  return (
    <ErrorMessageWrapper>
      <CardWrapper>
        <Card>
          <CardHead>
            <Icon
              src={icon || IconList.Error}
              style={{ width: '46px', height: '46px', }}
            />
          </CardHead>
          <CardBody>
            { message }
          </CardBody>
          <CardFooter>
            { description }
          </CardFooter>
          {retryMessage && retry && <Card>
            <ButtonWrapper onClick={()=>retry()}>
              {retryMessage}
            </ButtonWrapper>
          </Card>}
        </Card>
      </CardWrapper>
    </ErrorMessageWrapper>
  )
}

export default ErrorMessage

const ButtonWrapper = styled.div`
  cursor: pointer;
  text-align:center;
  color:white;
  padding:5px;
  background: #69758C;
  box-shadow: 0px 2px 10px rgba(4, 7, 13, 0.1);
  border-radius: 4px;
  width: 150px;
  margin:auto;
  margin-top:20px;
`

const CardWrapper = styled.div`
  padding: 100px 0 200px 0;
  background: #FFFFFF;
  box-shadow: 0px 2px 10px rgba(4, 7, 13, 0.1);
  border-radius: 4px;
`


const ErrorMessageWrapper = styled.div`
  max-width: 700px;
  margin: 64px auto 24px auto;

  ${CardHead}, ${CardBody}, ${CardFooter} {
    display: flex;
    justify-content: center;
    text-align: center;
  }

  ${CardBody} {
    padding: 30px 0;
    font-size: 40px;
    font-weight: bold;
    width : 85%;
    margin: auto;
    font-size: 30px;
  }

  ${CardFooter} {
    width:80%;
    margin:auto;
    font-size: 14px;
  }

  @media ${media.mobile} {
    margin: 0;
    padding: 15px;

    ${CardWrapper} {
      padding-right: 20px;
      padding-left: 20px;

      ${CardBody} {
        font-size: 30px;
      }
    }
  }
`
