import { useRef } from "react";
import styled from "styled-components";
import { IResponseApiGetCharge } from "../../services/charges.transform";
import { message, translate } from "../../i18n/translate.helper";
import {
  getFriendlyDate,
  getFriendlyPaymentMethod,
  getPayAmount,
} from "../../helpers/utils";
import Action from "./Action";
import Button from "../Build/Button";
import Detail from "./Detail";
import Heading from "../Build/Heading";
import Icon, { IconList } from "../Build/Icon";

type Props = {
  capturingScreen: boolean;
  responseApiGetCharge: IResponseApiGetCharge;
  onClickSaveFile?: (element: HTMLDivElement) => void;
  onClickShareEmail?: () => void;
  onClickBackToMerchantPage:
    | ((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
    | null;
};

const Receipt = (props: Props) => {
  const {
    capturingScreen,
    responseApiGetCharge,
    onClickSaveFile,
    onClickShareEmail,
    onClickBackToMerchantPage,
  } = props;
  const wrapperRef = useRef<HTMLDivElement>(null);

  const onClickSaveFileButton = () => {
    if (wrapperRef.current && onClickSaveFile) {
      onClickSaveFile(wrapperRef.current);
    }
  };

  const onClickShareEmailButton = () => {
    if (onClickShareEmail) {
      onClickShareEmail();
    }
  };

  const onClickBackToMerchant = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (onClickBackToMerchantPage) {
      onClickBackToMerchantPage(e);
    }
  };

  const { iconSrc, header, description } = responseApiGetCharge.status === "pending" ? {
    iconSrc: IconList.Pending,
    header: translate(message.payment_pending),
    description: translate(message.payment_pending_desc),
  }: {
    iconSrc: IconList.Check,
    header: translate(message.payment_successful),
    description: null,
  }

  return (
    <Wrapper>
      <Inner capturingScreen={capturingScreen} ref={wrapperRef}>
        <Icon
          src={iconSrc}
          style={{ width: "40px", height: "40px", marginBottom: "16px" }}
        />
        <Heading level={2}>{header}</Heading>
        {description && <PendingDescription>{description}</PendingDescription>}
        <Heading level={3}>
          {`${responseApiGetCharge.currency}`.toUpperCase()}{" "}
          {getPayAmount(
            responseApiGetCharge.amount,
            responseApiGetCharge.currency.toUpperCase()
          )}
        </Heading>
        <PaymentDetails>
          <Detail
            items={[
              {
                label: translate(message.charge_id),
                value: responseApiGetCharge.id,
              },
              {
                label: translate(message.payment_date),
                value: getFriendlyDate(responseApiGetCharge.paymentDate),
              },
              {
                label: translate(message.payment_method),
                value: getFriendlyPaymentMethod({
                  sourceType: responseApiGetCharge.source.type,
                  cardBrand: responseApiGetCharge.card.brand,
                  cardLastDigits: responseApiGetCharge.card.lastDigits,
                }),
              },
            ]}
          />
        </PaymentDetails>
        <MerchantOrder>
          <Detail
            title={responseApiGetCharge.additional}
            items={responseApiGetCharge.metadata.map((item) => ({
              label: item.name,
              value: item.value,
            }))}
          />
        </MerchantOrder>
        <Action style={{ marginTop: "50px" }} className="action">
          <Button
            onClick={onClickSaveFileButton}
            outline={true}
            style={{
              width: "50%",
              borderColor: "#D0D6E2",
              fontWeight: "normal",
            }}
          >
            {translate(message.save_to_files)}
          </Button>
          <Button
            onClick={onClickShareEmailButton}
            outline={true}
            style={{
              width: "50%",
              borderColor: "#D0D6E2",
              fontWeight: "normal",
            }}
          >
            {translate(message.share_to_email)}
          </Button>
        </Action>
        {onClickBackToMerchantPage && (
          <Action style={{ marginTop: "16px" }} className="action">
            <Button
              data-cy="btn_back-to-merchant"
              onClick={onClickBackToMerchant}
              style={{
                backgroundColor: "#1500FF",
                width: "100%",
                fontWeight: "normal",
              }}
            >
              {translate(message.back_to_merchant)}
            </Button>
          </Action>
        )}
      </Inner>
    </Wrapper>
  );
};

export default Receipt;

type WrapperProps = {
  capturingScreen: boolean;
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0px 2px 10px rgba(4, 7, 13, 0.1);
  box-sizing: border-box;
`;

const Inner = styled.div.attrs((props: WrapperProps) => ({
  capturingScreen: props.capturingScreen,
}))`
  padding: 24px 24px 32px 24px;
  background: #fff;
  border-radius: ${(props) => (props.capturingScreen ? "0" : "4px")};

  .action {
    display: flex;
    justify-content: space-between;
  }
`;

const PendingDescription = styled.div`
  font-size: 12px;
  color: #98A1B2;
  margin-bottom: 8px;
`;

const PaymentDetails = styled.div`
  margin-top: 34px;
`;

const MerchantOrder = styled.div`
  margin-top: 24px;
`;
