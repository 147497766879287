import { ErrorResponse } from "../../helpers/enums";
import { message, useIntl } from "../../i18n/translate.helper";
import ErrorMessage from "../Combine/ErrorMessage";
import Footer from "../Combine/Footer";
import { getPspFromURL } from "../../plugins/omisejs-wrapped/helpers/utils";
import { isExternalSecuredBy } from "../../helpers/utils"
import { IPaymentFormConfiguration } from "../../services/link.transform";

type Props = {
  type: ErrorResponse;
  paymentFormConfiguration: IPaymentFormConfiguration;
  retry?:()=>void
};

const InvalidLink = ({ type, paymentFormConfiguration, retry }: Props) => {
  const intl = useIntl();
  const errorDetail=()=>{
    const format=(internalMsg:string)=>intl.formatMessage({id:internalMsg})
    
    switch (type) {
      case ErrorResponse.UnknownStatus:
        return {
          message:format(message.something_went_wrong),
          description:format(message.contact_merchant),
          retryMessage:format(message.try_again),
          retry
        }
      case ErrorResponse.NoAccess:
        return {
          message:format(message.no_access_title),
          description:format(message.no_access_description),
        }
      default:
        return {
          message:format(message.oops_link_is_invalid),
          description:format(message.link_has_been_used_or_doesnt_exist),
        }
    }
  }
  return (
    <>
      <ErrorMessage {...errorDetail()}
      />
      <Footer
        showSecuredBy={!getPspFromURL()}
        showExternalSecuredBy={isExternalSecuredBy(paymentFormConfiguration)}
        paymentFormConfiguration={paymentFormConfiguration}
      />
    </>
  );
};

export default InvalidLink;
